.cart {
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      background-color: #ffffff;
      tr {
        th {
          padding: 10px 5px;
          text-align: left;
          border-bottom: 1px solid #333333;
          font-size: 14px;
        }
      }
    }
    tbody {
      tr {
        font-size: 13px;
        td {
          padding: 10px 0;
          border-bottom: 1.5px dashed #333333;


          input {
            width: 50px;
            padding: 5px;
            text-align: center;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
          }
        }
      }
    }
  }
}


.cart-item {
  font-size: 13px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    &.cart-item__details {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px solid #e0e0e0;
    }
    li {
      display: inline-block;
      margin-right: 10px;
      &.cart-item__image {
        width: 50px;
        height: 50px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .cart-item__name {
        font-weight: 600;
      }
      .cart-item__price {
        font-weight: 600;
      }
      .cart-item__quantity {
        input {
          width: 50px;
          padding: 5px;
          text-align: center;
          border: 1px solid #e0e0e0;
          border-radius: 5px;
        }
      }
      .cart-item__remove {
        cursor: pointer;
        color: #f44336;
      }
    }
  }
}

.single-product {
  cursor: pointer;

  &.selected {
    // background-color: #f5f5f5;
    background-color: rgba(68, 55, 146, 0.4);
    cursor: not-allowed;
  }

  .card-title {
    font-size: 16px;
    font-weight: 600;
  }
}

.total-area {
  font-size: 14px;

  p {
    margin: 0;
  }
}